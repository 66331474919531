export const RECAPTCHA_ERROR = 'recaptcha_error';

export const PASSWORD_TOO_SHORT = 'password_too_short';
export const PASSWORD_MISSING_CASE = 'password_missing_case';
export const PASSWORD_MISSING_NUMBER_OR_SYMBOL = 'password_missing_number_or_symbol';

export const PASSWORD_ERRORS = {
  PASSWORD_TOO_SHORT,
  PASSWORD_MISSING_CASE,
  PASSWORD_MISSING_NUMBER_OR_SYMBOL,
};
